<template>
  <div style="margin-top: 20px;margin-left: 20px;">
    <el-row>
      <el-col :span="24">
        <div>
          <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="标题">
              <el-input v-model="queryForm.title" placeholder="标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="内容">
              <el-input v-model="queryForm.content" placeholder="内容" clearable></el-input>
            </el-form-item>
            <el-form-item label="作者">
              <el-input v-model="queryForm.author" placeholder="作者" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getMaterialPage()">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="24">
        <div>
          <el-card class="box-card" shadow="hover">
            <el-table
                :data="tableData"
                style="width: 100%">
              <el-table-column
                  prop="title"
                  sortable
                  label="标题">
              </el-table-column>
              <el-table-column
                  prop="content"
                  sortable
                  label="内容">
                <template slot-scope="scope">
                    <div class="wzyc" v-html="scope.row.content.replace(/\n/g, '<br/>')"></div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="enclosure"
                  sortable
                  label="附件">
                <template slot-scope="scope">
                  <span v-if="!scope.row.enclosure">-</span>
                  <span v-else>
                    <a :href="downloadUrl + scope.row.enclosure" target="_blank">下载</a>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="author"
                  sortable
                  label="作者">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  sortable
                  label="创建时间">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作"
                  width="100">
                <template slot-scope="scope">
                  <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex;justify-content: center;margin-top: 10px;">
              <el-pagination
                  layout="prev, pager, next"
                  @current-change="currentChange"
                  :total="total">
              </el-pagination>
            </div>

          </el-card>
        </div>
      </el-col>
    </el-row>

    <!-- 弹出层 -->
    <div>
      <el-dialog :visible.sync="dialogTableVisible" width="100%">
        <div>
          <div style="display: flex;justify-content: center;font-size: 20px;">{{queryData.title}}</div>
          <div style="display: flex;justify-content: center;font-size: 14px;color: #909399;margin-top: 10px;">作者：{{queryData.author}}</div>
          <div v-html="queryData.content.replace(/\n/g, '<br/>')"></div>
          <div style="font-size: 14px;color: #909399;margin-top: 10px;">创建时间：{{queryData.createTime}} &nbsp;&nbsp;&nbsp;&nbsp;<a v-if="queryData.enclosure" :href="downloadUrl + queryData.enclosure" target="_blank">附件下载</a></div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import interviewApi from "@/api/interview";
import base from "@/api/base";
export default {
  name: "material",
  data() {
    return {
      input3: '',
      formInline: {},
      tableData: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      downloadUrl: base.baseURL + "/file/download?url=",
      dialogTableVisible: false,
      queryData: {
        title: '',
        author: '',
        createTime: '',
        content: ''
      }
    }
  },
  created() {
    this.getMaterialPage()
  },
  methods: {
    getMaterialPage() {
      interviewApi.getMaterialPage(this.queryForm).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleClick(row) {
      this.dialogTableVisible = true
      this.queryData = row
    },
    currentChange(page) {
        this.queryForm.pageNum = page
        this.getMaterialPage()
    }

  }
}
</script>

<style scoped>
  .wzyc{
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
</style>
